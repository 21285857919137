import React from 'react'

import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'

export default () => (
  <Layout>
    <PageTitle name="Thank you!" />
    <p>You joined the listserv!</p>
  </Layout>
)
